<template>
    <!--  <img alt="Vue logo" src="./assets/logo.png">-->
    <!--  <HelloWorld msg="Welcome to Your Vue.js App" style="color: firebrick"/>-->
    <wavesBg :top="90">


    </wavesBg>
    <div style="display: flex; position: absolute; z-index: 99;justify-content: center ;width: 98%;">
        <view style="" >
            <view style="display: flex;flex-direction:column ;align-content: center;justify-content: flex-end;height: 100%">
                <h1 style="color: #ffffff">网站正在建设中</h1>
                <view style=" height: calc(100vh - 150px);;"></view>
                <view style="color:#ffffff;" @click="increment()">黑ICP备2022002154号-1</view>
            </view>
        </view>

    </div>

</template>

<script>
    import wavesBg from './components/waves.vue'

    export default {
        name: 'App',
        components: {
            wavesBg
        },
        // data() 返回的属性将会成为响应式的状态
        // 并且暴露在 `this` 上
        data() {
            return {
                count: 0
            }
        },
        methods: {
            increment() {
                window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
            }
        },

    }
</script>

<style>
    /*#app {*/
    /*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
    /*  -webkit-font-smoothing: antialiased;*/
    /*  -moz-osx-font-smoothing: grayscale;*/
    /*  text-align: center;*/
    /*  color: #2c3e50;*/
    /*  margin-top: 60px;*/
    /*}*/
</style>
